import Link from "next/link";

export default function Error() {
	return (
		<>
			<div />
			<div className="p-12">
				<h1 className="mb-12 text-5xl">404</h1>
				<p className="mb-8 text-2xl">Page not found</p>
				<Link href="/">Go home</Link>
			</div>
		</>
	);
}
